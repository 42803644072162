import React from 'react';
import styled from 'styled-components';
import ContactForm from '../components/forms/ContactForm';
import Layout from '../components/Layout';
import Wrapper from '../styles/utilities/Wrapper';
import { misc, colors } from '../styles/utilities/settings';
import { above } from '../styles/utilities/mediaQueries';
import SvgLoader from '../components/helpers/SvgLoader';

const ContactUsPage = ({
  pageContext: {
    content: { cityStateZip, mainEmail, phone, street },
  },
}) => (
  <Layout title="Contact Us" path="contact-us">
    <SContactUs>
      <Wrapper narrow>
        <h1>Get In Touch</h1>
        <div className="form-inner">
          <ContactForm />
          <div>
            {street && cityStateZip && (
              <p>
                <SvgLoader name="marker" width={15} color={colors.red} />
                <small>Address</small>
                <a
                  href={`https://www.google.com/maps/place/${street} ${cityStateZip}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {street}
                  <br />
                  {cityStateZip}
                </a>
              </p>
            )}
            <p>
              <SvgLoader name="email" width={15} color={colors.red} />
              <small>Email:</small>
              <a href={`mailto:${mainEmail}`}>{mainEmail}</a>
            </p>
            <p>
              <SvgLoader name="phone" width={15} color={colors.red} />
              <small>Phone Number:</small>
              <a href={`tel:${phone}`}>{phone}</a>
            </p>
          </div>
        </div>
      </Wrapper>
    </SContactUs>
  </Layout>
);

export default ContactUsPage;

const SContactUs = styled.div`
  padding: ${misc.sectionMargin}px 0;

  a {
    color: ${colors.text};
    text-decoration: none;
  }

  h1 {
    color: ${colors.darkBlue};
  }

  .form-inner {
    ${above.ipadPort`
      display: flex;
      flex-direction: row-reverse;
    `}

    > div {
      ${above.ipadPort`
        width: 66.6666%;
      `}
      &:last-child {
        ${above.ipadPort`
          width: 33.3333%;
        `}
      }

      p {
        position: relative;
        display: block;
        padding-left: 20px;

        small {
          display: block;
          font-size: 10px;
          text-transform: uppercase;
          font-weight: 600;
        }

        svg {
          position: absolute;
          top: 3px;
          left: 0;
        }
      }
    }
  }
`;
